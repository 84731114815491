import React from "react";
import Layout from "components/Layout";

const NotFoundPage = () => (
    <Layout>
        <h1>
            NOT FOUND
        </h1>
        <p>
            Uh oh, it looks like that route that doesn&#39;t exist. Don&#39;t worry though! There's plenty more to see around the main website.
        </p>
    </Layout>
)

export default NotFoundPage;